import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import NewButton from "../NewButton"

import SliderPagination from "../SliderPagination"
import { slides } from "./mockedData"
import { useWindowWidth } from "../../services/hooks"
import WebpImage from "../WebpImage"

import "./style.scss"

const ExamHowItWorks = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [slidesToShow, setSlidesToShow] = useState(1)

  const sliderRef = useRef()

  const settings = {
    adaptiveHeight: false,
    speed: 500,
    draggable: false,
    arrows: false,
    infinite: false,
    slidesToShow,
    slidesToScroll: slidesToShow,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }

  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (windowWidth > 1199) {
      setSlidesToShow(3)
    } else if (windowWidth > 768) {
      setSlidesToShow(2)
    } else {
      setSlidesToShow(1)
    }
  }, [windowWidth])

  useEffect(() => {
    sliderRef.current.slickGoTo(0)
    setCurrentPage(1)
    setAllPages(Math.ceil(slides.length / slidesToShow))
  }, [slidesToShow])

  return (
    <div className="exam-how-it-works">
      <div className="how-it-works-title grid-section">
        <div>
          <h3 className="text h3 green">Here’s How It Works</h3>
        </div>
      </div>
      <div className="slider-wrapper grid-section">
        <Slider ref={sliderRef} className="slider-container" {...settings}>
          {slides.map(({ id, image, title, description,alt }) => (
            <div className="carousel-item" key={id}>
              <WebpImage fileName={image} alt={alt} />
              <div className="carousel-copy">
                <span className="id text h7 bulky white">{id}</span>
                <p className="title text h6 green">{title}</p>
                <p className="description text h7 green">{description}</p>
              </div>
            </div>
          ))}
        </Slider>
        <SliderPagination
          allPages={allPages}
          currentPage={currentPage}
          sliderRef={sliderRef}
        />
        <div className="button-container">
          <NewButton label="Start Your Subscription" className="" to={"/contact-lenses/hubble"} />
        </div>
      </div>
    </div>
  )
}

export default ExamHowItWorks
