import React from "react"

import "./style.scss"
import { props } from "./mockedData"
import NewButton from "../NewButton"
import WebpImage from "../WebpImage"

const ExamProps = () => {
  return (
    <div className="exam-props">
      <div className="grid-section">
        <h3 className="heading text h3 green">Here’s What You’ll Need</h3>
        <div className="props-container">
          {props.map(({ title, description, image }, index) => (
            <div className="prop-item" key={index}>

              <WebpImage className="square-icon" fileName={image} alt="" />
              <p className="title text h7 neue-bold">{title}</p>
              <p className="description text h7">{description}</p>
            </div>
          ))}
        </div>
        <div className="button-container">
          <NewButton label="Start Your Subscription" to={"/contact-lenses/hubble"} />
        </div>
      </div>
    </div>
  )
}

export default ExamProps
