import React, { useState } from "react"
import axios from "axios"

import "./style.scss"
import NewButton from "../NewButton"

const getLink = address =>
  `https://www.google.com/maps/search/?api=1&query=${address}`

const ExamFindDoctor = () => {
  const [zip, setZip] = useState("")
  const [doctors, setDoctors] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleChange = e => setZip(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(false)

    const obj = {
      name: "",
      phone: "",
      email: "",
      zip,
    }

    axios
      .post(
        `https://admin.hubblecontacts.com/v1/docs/create_doc_request.json`,
        obj,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(response => {
        setDoctors(response.data.doctors)
        setIsSubmitted(true)
        if (typeof window !== "undefined") {
          window.dataLayer.push({
            event: "doctor_search",
            zip_code: obj.zip,
            number_of_results: response.data.doctors.length
          })
        }

      })
  }

  return (
    <div className="exam-find-doctor" id="doctor-locator">
      <div className="grid-section">
        <h3 className="heading text h3 green">Need to visit the eye doctor?</h3>
        <p className="description text h7 green">
          Our free online vision test is not meant to replace a comprehensive eye exam, or provide you with your first vision prescription. But, don’t worry, we can help you find an eye care professional in your area.
        </p>
        <form className="find-doctor" onSubmit={handleSubmit}>
          <input
            value={zip}
            onChange={handleChange}
            placeholder="What’s your zip code?"
            className="text h7"
            required
          />
          <NewButton type="submit" label="Find a Doctor" />
        </form>
        {isSubmitted &&
          (!!doctors.length ? (
            <>
              <p className="results text h7 green">
                {`We found ${doctors.length} doctor${
                  doctors.length > 1 ? "s" : ""
                } in your area:`}
              </p>
              <DesktopTable doctors={doctors} />
              <MobileTable doctors={doctors} />
            </>
          ) : (
            <p className="results text h7 green">
              We found no doctors in your area.
            </p>
          ))}
      </div>
    </div>
  )
}

const DesktopTable = ({ doctors }) => (
  <table className="doctors">
    <thead>
      <tr className="text h9 neue-bold green uppercase">
        <th>Clinic name</th>
        <th>Telephone number</th>
        <th>Address</th>
      </tr>
    </thead>
    <tbody>
      {doctors.map(
        ({ clinic_name, phone, address_1, city, state, postcode }, index) => (
          clinic_name && <tr className="text h7 green" key={index}>
            <th>{clinic_name}</th>
            <th>{phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</th>
            <th>
              <a
                href={getLink(`${address_1}, ${city}, ${state}, ${postcode}`)}
                target="_blank"
              >{`${address_1}, ${city}, ${state}, ${postcode}`}</a>
            </th>
          </tr>
        )
      )}
    </tbody>
  </table>
)

const MobileTable = ({ doctors }) => (
  <table className="mobile-doctors">
    <tbody>
      {doctors.map(
        ({ clinic_name, phone, address_1, city, state, postcode }, index) => (
          clinic_name && <tr key={index}>
            <th>
              <p className="title text h9 neue-bold green uppercase">
                Clinic name
              </p>
              <p className="value text h7 green">{clinic_name}</p>
              <p className="title text h9 neue-bold green uppercase">
                Telephone number
              </p>
              <p className="value text h7 green underline">{phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</p>
              <p className="title text h9 neue-bold green uppercase">Address</p>
              <a
                href={getLink(`${address_1}, ${city}, ${state}, ${postcode}`)}
                target="_blank"
                className="value text h7 green underline"
              >{`${address_1}, ${city}, ${state}, ${postcode}`}</a>
            </th>
          </tr>
        )
      )}
    </tbody>
  </table>
)

export default ExamFindDoctor
