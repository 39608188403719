import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ExamHeading from "../components/ExamHeading"
import ExamHowItGoes from "../components/ExamHowItGoes"
import ExamHowItWorks from "../components/ExamHowItWorks"
import ExamCta from "../components/ExamCta"
import ExamProps from "../components/ExamProps"
import FAQBlock from "../components/FAQBlock"
import ExamComparison from "../components/ExamComparison"
import ExamFindDoctor from "../components/ExamFindDoctor"
import { examFaq } from "../services/data"

const Exam = () => {
  const schemaMarkup = [{
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": "https://www.hubblecontacts.com/vision-tests/",
    "potentialAction": {
      "@type": "SearchAction",
      // TODO: get clarity on what search_term_string should be
      "target": "www.hubblecontacts.com/vision-tests/?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  },
  {
    "@context": "http://schema.org",
    "@type": "Service",
    "name": "Free Online Vision Test",
    "description": "Take a free online vision test and renew your contact lens prescription with Hubble. Get fast and accurate results using your personal device and from the comfort of your home. Receive personalized recommendations from an eye care professional to improve your vision.",
    "provider": {
      "@type": "Organization",
      "name": "Hubble Contacts.",
      "url": "https://www.hubblecontacts.com"
    },
    "areaServed": {
      "@type": "AdministrativeArea",
      "name": "United States"
    },
    "category": "Health and Wellness",
    "serviceType": "Free Online Vision Test",
    "serviceOutput": {
      "@type": "MedicalTest",
      "name": "FreeOnline Vision Test",
      "description": "Take a free online vision test and renew your contact lens prescription with Hubble. Get fast and accurate results using your personal device and from the comfort of your home. Receive personalized recommendations from an eye care professional to improve your vision.",
      "mainEntityOfPage": "https://www.hubblecontacts.com/vision-tests/",
      "guideline": [
      ]
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What do I need to take the online vision test?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Good news! You probably have everything you need right in your home:\n10 feet of space\nA smartphone, tablet, or computer\nYour prescription for your current correction\nAnd remember, you need to wear your current lenses throughout the online vision test."
        }
      },
      {
        "@type": "Question",
        "name": "How do I know if I can take the test for free?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There are just a few requirements to qualify for the free online vision tests for contacts. To be eligible for the online vision test, you must: Reside in a U.S. state where our test is currently activated (view a list of active states here) Be between the ages of 18-55 Have no health conditions or take any medication that may impact your vision, Be a Hubble customer with an upcoming expiring Hubble contact lens prescription"
        }
      },
      {
        "@type": "Question",
        "name": "When can I get the online vision test?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you need to renew your Hubble contacts prescription, this test is for you. Best part? You can take it right now, right from home—no eye doctor appointment needed.\n\nThe test evaluates the visual acuity in your current correction and sends the results for review by a licensed eye doctor in your state who writes a new prescription."
        }
      },
      {
        "@type": "Question",
        "name": "Who is the vision test made for?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "This test is for people whose vision has not changed, have recently completed a comprehensive eye exam, and are looking to renew an expiring Hubble prescription. If the test detects that your vision has changed, your prescription will not be renewed. And we recommend that you see an in-person doctor. If this is your first prescription, you will need to consult an eye care professional for an eye examination. This test does not replace an initial eye examination. It does not perform or replace a comprehensive eye examination, nor does it assess eye health. Consult an eye care professional for a comprehensive eye examination yearly or any time you are experiencing pain or discomfort. This test is not intended to diagnose, treat, mitigate, or cure disease."
        }
      },
      {
        "@type": "Question",
        "name": "How does the online vision test work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you’re eligible for the free online vision test for contacts, we’ll email you to let you know it’s time to renew your contact prescription online. The email will direct you to our website where you can take the test right from home—all you need is your smartphone, tablet, or computer. Next, a licensed eye doctor will review the results and write your new prescription. Once your new prescription is confirmed, we’ll send it to you via email and add it to your subscription. It’s that easy."
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take to complete the vision test?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Most people complete our online test in 10 minutes or less! 90% of prescriptions from our licensed doctors are delivered within 24 hours."
        }
      },
      {
        "@type": "Question",
        "name": "How often should I take an online eye exam/vision test?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A majority of contact lens prescriptions expire within one to two years following the data of your last eye exam or vision test, so you’ll want to make time to take the test prior to your prescription’s expiration date! You must possess a valid prescription to order or start a subscription with Hubble contacts. Keep in mind that this test is for people whose vision has not changed, have recently completed a comprehensive eye exam, and are looking to renew an expiring Hubble prescription. If you do not fall within these categories, use Hubble’s find a doctor tool above to locate an eye care professional near you."
        }
      }

    ]
  },
  {
    "@context": "https://schema.org",
    "@url": "https://hubblecontacts/vision-tests",
    "@type": "WebPage",
    "name": "Hubble: Free At-Home Online Vision Test: Fast Contacts Prescription Renewal",
    "description": "Take a free online vision test and renew your contact lens prescription with Hubble. Get fast and accurate results using your personal device and from the comfort of your home. Receive personalized recommendations from an eye care professional to improve your vision.",
    "publisher": {
        "@type": "Organization",
        "name": "Hubble"
    }
  }
  ]
  return (
    <Layout>
      <SEO
        title="Hubble: Free At-Home Online Vision Test: Fast Contacts Prescription Renewal"
        description="Take a free online vision test and renew your contact lens prescription with Hubble. Get fast and accurate results using your personal device and from the comfort of your home. Receive personalized recommendations from an eye care professional to improve your vision."
        schemaMarkup={schemaMarkup} />
      <ExamHeading />
      <ExamHowItGoes />
      <ExamHowItWorks />
      <ExamCta />
      <ExamProps />
      <ExamFindDoctor />
      <FAQBlock color='green' faqList={examFaq} title="Got questions about our online vision test? We've got answers." />
      <ExamComparison />
    </Layout>
  )
}

export default Exam
