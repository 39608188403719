export const slides = [
  {
    id: 1,
    image: "Pages/exam/Hubble-Free-Online-Eye-Exams-How-It-Works-01.jpg",
    title: "It's Time To Renew Your Rx",
    alt: "Happy woman wearing her Hubble eyeglasses",
    description:
      "We'll email you to let you know.",
  },
  {
    id: 2,
    image: "Pages/exam/Hubble-Free-Online-Eye-Exams-How-It-Works-02.jpg",
    title: "Take the FREE Vision Test Online",
    alt: "Happy man wearing Hubble eyeglasses working on his laptop at home",
    description:
      "Via your smartphone, tablet, or computer.",
  },
  {
    id: 3,
    image: "Pages/exam/Hubble-Free-Online-Eye-Exams-How-It-Works-03.jpg",
    title: "A Doctor Reviews Your Results",
    alt: "Eye doctor writing down test results",
    description:
      "A licensed eye doctor writes your renewal prescription.",
  },
  {
    id: 4,
    image: "Pages/exam/Hubble-Free-Online-Eye-Exams-How-It-Works-04.jpg",
    title: "We Confirm Your Prescription",
    alt: "Easily searching the Hubble Contacts website on your phone",
    description:
      "Check your email - your Rx is all set.",
  },
  {
    id: 5,
    image: "Pages/exam/Hubble-Free-Online-Eye-Exams-How-It-Works-05.jpg",
    title: "Add Rx To Your Hubble Order And we’ll update your subscription Plus, take your vision test with us and your Rx will automatically be connected to your Hubble order.",
    alt: "Man unboxing a new delivery of Hubble Contacts",
    description:
      "And we'll update your subscription.",
  },
]
