export const props = [
  {
    title: "Your Smartphone, Tablet, or Computer",
    description: "No app needed! You take the test right from your device.",
    image: "Pages/exam/_Icons/Free-Eye-Exams-Smartphone.png",
  },
  {
    title: "10 Feet of Space",
    description:
      "Back it up! Stand around 10 feet from your screen so we can accurately measure your vision.",
    image: "Pages/exam/_Icons/Free-Eye-Exams-10-feet.png",
  },
  {
    title: "Most Recent Prescription",
    description:
      "Come prepared! Your previous prescription with Hubble is required.",
    image: "Pages/exam/_Icons/Free-Eye-Exams-Prescription.png",
  },
]
