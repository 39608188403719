import React from "react"

import "./style.scss"
import { comparisonInfo, labels } from "./mockedData"
import CheckedIcon from "../../images/assets/Comparison/Checked.svg"

const ExamComparison = () => {
  return (
    <div className="exam-comparison">
      <div className="grid-section">
        <h3 className="heading text h3 green">Vision Tests: Hubble vs. Doctor’s Office</h3>
        <div className="table-container">
          <DesktopTable comparisonInfo={comparisonInfo} />
          <p className="disclaimer text h9" id="visibly-states">
            *The vision test can be taken from anywhere there is an internet connection available,
            including the comfort of your own home. Currently, the states in which the vision test
            can be completed include: AL, AR, AZ, CA, CO, CT, FL, HI, IA, IL, IN, KY, MA, MD, MN,
            MO, MS, MT, NC, ND, NE, NH, NV, NY, OH, OR, PA, SD, TN, TX, UT, VA, WA, WI, and WY.
          </p>
        </div>
      </div>
    </div>
  )
}

const DesktopTable = ({ comparisonInfo }) => {
  return (
    <table className="comparison-table">
      <thead>
        <tr className="text h6">
          <th className="blank-cell"></th>
          <th data-type="hubble">Hubble</th>
          <th>Doctor’s Office</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(comparisonInfo).map(
          ([key, { hubble, doctor }], index) => (
            <tr key={index}>
              <th
                className="text h9 neue-bold green uppercase"
                data-type="caption"
              >
                {labels[key]}
              </th>
              <th className="text h7" data-type="hubble">
                <div
                  className="text h9 neue-bold green uppercase mobile"
                  data-type="caption"
                >
                  {labels[key]}
                </div>
                {typeof hubble === "string" ? hubble : <CheckedIcon />}
              </th>
              <th className="text h7">
                <div
                  className="text h9 neue-bold green uppercase mobile"
                  data-type="caption"
                >
                  {labels[key]}
                </div>
                {typeof doctor === "string" ? doctor : <CheckedIcon />}
              </th>
            </tr>
          )
        )}
      </tbody>
    </table>
  )
}

export default ExamComparison
