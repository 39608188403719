export const comparisonInfo = {
  time: {
    hubble: "10 minutes",
    doctor: "1 hour + travel",
  },
  cost: {
    hubble: "Free! This one is on us",
    doctor: "$100+",
  },
  reviewed: {
    hubble: true,
    doctor: true,
  },
  availability: {
    hubble: "Anytime",
    doctor: "9 am - 5 pm",
  },
  location: {
    hubble: "From the comfort of your home",
    doctor: "At an actual office",
  },
  accessibility: {
    hubble: "You can access your prescription anywhere, anytime",
    doctor: "A paper copy only",
  },
  simplicity: {
    hubble: "All you need is your smartphone and a computer to get started",
    doctor:
      "Find the time. Make the appointment. Get to the appointment. Wait. Have the appointment. Travel back home",
  },
  convenience: {
    hubble: "Your Rx is automatically connected to your Hubble order",
    doctor: "You have to manually enter in your prescription",
  },
}

export const labels = {
  time: "Time spent",
  cost: "Cost",
  reviewed: "Doctor reviewed",
  availability: "When can you do it?",
  location: "Location?",
  accessibility: "Accessible",
  simplicity: "Simple",
  convenience: "Convenient",
}
