import React from "react"

import "./style.scss"
import WebpImage from "../WebpImage"

const ExamHeading = () => {
  return (
    <div className="exam-heading">
      <div className="exam-heading--background">
        <WebpImage fileName="Pages/exam/Hubble-Free-Online-Eye-Exams-Hero-Module-Desktop.jpg" />
      </div>
      <div className="grid-section">
        <div className="hero-text">
          <div className="title">
            <h1 className="text h2 bulky-wide white">
              FREE Online Vision Tests
            </h1>
          </div>
          <div>
            <p className="text h7 neue white">
              Another way Hubble makes contacts easy. Existing subscriber? Renew your contact lens subscription on your phone in minutes from the comfort of your own home..
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamHeading
