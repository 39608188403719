import React from "react"
import WebpImage from "../WebpImage"

import "./style.scss"

const ExamCta = () => {
  return (
    <div className="exam-cta">
      <div className="exam-cta--background">
        <WebpImage wrapperClassName="desktop-image" className="desktop-image" fileName="Pages/exam/Hubble-Free-Online-Eye-Exams-Banner-Desktop.jpg" />
        <WebpImage wrapperClassName="mobile-image" className="mobile-image" fileName="Pages/exam/Hubble-Free-Online-Eye-Exams-Banner-Mobile.jpg" />
      </div>
      <div className="grid-section">
        <h2 className="title text h2 bulky-wide green">
          A free online eye exam vision test for contacts
        </h2>
        <p className="description text h5 green">
          In under 10 minutes. Via your device. With 90% of prescriptions
          delivered in 24 hours.
        </p>
      </div>
    </div>
  )
}

export default ExamCta
